import { useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import PostButton from "../../common/PostButton";
import ClipboardButton from "../../common/ClipboardButton";
import axios from "axios";
import jwt from "jsonwebtoken";

function CreatePostMobileModal({
  postMobile,
  setPostMobile,
  currentUser,
  isOpen,
  setIsOpen,
  newPost,
  setNewPost,
  searchTerm,
  setSearchTerm,
  setPosts,
  allPosts,
  setAnalyse,
  analyse,
  setCurrentIndex,
  keywords,
  setKeywords,
  posts,
  currentIndex,
  analyseIssues,
  highlightColor,
  setHighlightColor,
  selected,
  editdraftId,
  draftEdit,
  setdraftEdit,
}) {
  async function submitPost() {
    // hardcoded values for testing creation of post
    const userPost = [newPost, ["Bob", "Sally"], ["bob@example.org"]];
    await setPosts([...allPosts, userPost]);
    await setNewPost("");
    await setKeywords([new RegExp()]);
    await setHideTextbox(false);
    await setTimerOn(false);
    await setTimer(5);
    await setAnalyse(false);
    setPostMobile(false);
  }
  // showing/hiding React Highlighter component based on post edit
  const [hideTextbox, setHideTextbox] = useState(false);

  const startAnalyse = async () => {
    // pushes new item to array each time start analyse is clicked...will be changed based on API calls
    const userPost = [
      newPost,
      ["Bob", "Sally", "Ryan"],
      ["bob@example.org", "abc@example.org", "ya", "All", "all", "ja"],
    ];

    await setPosts([...allPosts, userPost]);
    await setCurrentIndex(allPosts.length);
    await setHideTextbox(true);
    await setAnalyse(!analyse);
    await setKeywords(
      ["bob@example.org", "abc@example.org", "ya", "All", "all", "ja"].map(
        (word) => RegExp(`\\b${word}\\b`)
      )
    );
  };

  useEffect(() => {
    if (analyse) {
      setKeywords(
        ["bob@example.org", "abc@example.org", "ya", "All", "all", "ja"].map(
          (word) => RegExp(`\\b${word}\\b`)
        )
      );
    } else {
      setKeywords([new RegExp()]);
    }
  }, [analyse, currentIndex, posts, setKeywords]);

  const testPostFB = async () => {
    // hardcoded values for testing creation of post
    const userPost = [newPost, ["Bob", "Sally"], ["bob@example.org"]];
    await setPosts([...allPosts, userPost]);
    await setNewPost("");
    await setKeywords([new RegExp()]);
    await setHideTextbox(false);
    await setTimerOn(false);
    // await setTimer(5);
    await setAnalyse(false);
    setIsOpen(false);
    // const authHeader = OauthHelper.getAuthHeaderForRequest(request);
    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };
    try {
      const getToken = async () => {
        const formData = new FormData();
        const token = localStorage.getItem("authToken");
        if (!token) {
          window.location.href = "/login";
        } else {
          try {
            const decoded = jwt.verify(
              token,
              "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
            );
            // console.log('Decoded: ', decoded);
            formData.append("username", currentUser.name);
            formData.append("email", currentUser.email);
            formData.append("desc", newPost);
            formData.append("platform", "twitter");

            if (imgPreviews != null) {
              for (let i = 0; i < imgPreviews.length; i++) {
                formData.append("photo", imgPreviews[i]);
              }
            }

            await axios.post(
              // `https://www.duodev.xyz/twitter/post/${decoded.id}`,
              // `https://www.duodev.xyz/twitter/upload/image/${decoded.id}`,
              `https://www.duodev.xyz/twitter/upload/multiImage/${decoded.id}`,

              formData,
              config
            );
            setImgPreviews();

            // let currentUser = res.data;

            // console.log('currentUser', currentUser);
          } catch (err) {
            // console.log(err);
            // localStorage.removeItem("authToken");
            alert("only 4 images max");
            setImgPreviews();
            // window.location.href = "/";
          }
        }
      };

      /* Facebook post axios
			await axios.post(
				'https://www.duodev.xyz/facebook/post',
				{
					body: newPost,
				},
				config
			);
			*/
      // for posting '/' in posts.route

      //   for twitter

      // await axios.post(
      //   "https://www.duodev.xyz/twitter/post/",
      //   {
      //     username: currentUser.name,
      //     email: currentUser.email,
      //     desc: newPost,
      //     platform: "twitter",
      //   },
      //   config
      // );
      // window.location.replace("/");
      getToken();
    } catch (error) {
      console.log("Post is empty");
    }

    //   await axios.post(
    //     "https://www.duodev.xyz/api/post/",
    //     {
    //       username: currentUser.name,
    //       email: currentUser.email,
    //       desc: newPost,
    //     },
    //     config
    //   );
    //   window.location.replace("/");
    // } catch (error) {
    //   console.log("Post is empty");
    // }
  };
  const saveDraft = async () => {
    const userPost = [newPost, ["Bob", "Sally"], ["bob@example.org"]];
    await setPosts([...allPosts, userPost]);
    await setNewPost("");
    await setKeywords([new RegExp()]);
    await setHideTextbox(false);
    await setTimerOn(false);
    // await setTimer(5);
    await setAnalyse(false);
    console.log("press");
    try {
      const getToken = async () => {
        const data = new FormData();
        const token = localStorage.getItem("authToken");
        if (!token) {
          window.location.href = "/login";
        } else {
          try {
            console.log("inside");
            const decoded = jwt.verify(
              token,
              "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
            );
            // console.log('Decoded: ', decoded);
            data.append("desc", newPost);

            if (imgPreviews != null) {
              for (let i = 0; i < imgPreviews.length; i++) {
                data.append("photo", imgPreviews[i]);
              }
            }
            // config
            // var config = {
            //   method: "post",
            //   url: `https://www.duodev.xyz/twitter/draft/post/${decoded.id}`,
            //   headers: {
            //     ...data.getHeaders(),
            //   },
            //   data: data,
            // };
            let config = {
              method: "post",
              url: `https://www.duodev.xyz/twitter/draft/post/${decoded.id}`,

              data: data,
            };
            // axios
            axios(config)
              .then((response) => {
                console.log("done!", response);
              })
              .catch((error) => {
                console.log("doesnt work", error);
              });
          } catch (err) {
            console.log("hmm", err);
            // window.location.href = "/";
          }
        }
      };
      getToken();
      await setPostMobile(false);
    } catch (error) {}
  };

  const updateDraft = async () => {
    const userPost = [newPost, ["Bob", "Sally"], ["bob@example.org"]];
    await setPosts([...allPosts, userPost]);
    await setNewPost("");
    await setKeywords([new RegExp()]);
    await setHideTextbox(false);
    await setTimerOn(false);
    // await setTimer(5);
    await setAnalyse(false);
    console.log("press");
    try {
      const getToken = async () => {
        const token = localStorage.getItem("authToken");
        if (!token) {
          window.location.href = "/login";
        } else {
          try {
            console.log("inside");
            const decoded = jwt.verify(
              token,
              "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
            );
            // console.log('Decoded: ', decoded);
            var data = JSON.stringify({
              desc : newPost
            });

            if (imgPreviews != null) {
              for (let i = 0; i < imgPreviews.length; i++) {
                data.append("photo", imgPreviews[i]);
              }
            }
            // config
            // var config = {
            //   method: "post",
            //   url: `https://www.duodev.xyz/twitter/draft/post/${decoded.id}`,
            //   headers: {
            //     ...data.getHeaders(),
            //   },
            //   data: data,
            // };
            let config = {
              method: "post",
              url: `https://www.duodev.xyz/twitter/draft/update/${decoded.id}/${editdraftId}`,
              headers:{
                'Content-Type':'application/json'
              },
              data: data,
            };
            // axios
            axios(config)
              .then((response) => {
                console.log("done!", response);
              })
              .catch((error) => {
                console.log("doesnt work", error);
              });
          } catch (err) {
            console.log("hmm", err);
            // window.location.href = "/";
          }
        }
      };
      getToken();
      await setPostMobile(false);
    } catch (error) {}
  };

  // timer for button
  const [timer, setTimer] = useState(5);
  const [timerOn, setTimerOn] = useState(false);
  // use hidden buttons with refs for file upload since they are can't be styled
  const hiddenImgInput = useRef(null);
  const hiddenVideoInput = useRef(null);

  // stores the FileObject for images uploaded by user
  const [imgPreviews, setImgPreviews] = useState();

  const handleImage = () => {
    hiddenImgInput.current.click();
  };

  const handleImgPreview = async (event) => {
    await setImgPreviews(event.target.files);
  };

  const handleVideo = () => {
    hiddenVideoInput.current.click();
  };

  useEffect(() => {
    let interval = null;

    if (timerOn) {
      interval = setInterval(() => {
        setTimer((prevTime) => prevTime - 1);
      }, 1000);
    } else if (!timerOn) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timerOn]);
  return (
    <div className="bg-gray-50 overflow-scroll h-full w-full z-10 fixed top-0">
      <div className="flex justify-between px-4 py-4 bg-white shadow-md">
        <button
          onClick={async () => {
            await setAnalyse(false);
            await setPostMobile(false);
            await setNewPost("");
            await setKeywords([new RegExp()]);
          }}
          className="max-w-max max-h-max flex "
        >
          <img src="/imgs/cross.svg" alt="cross" />
        </button>
        {selected !== "Drafts" ?
          <>
            <h2 className="font-bold text-ss-blue">Create Post</h2>
            <button
              onClick={testPostFB}
              type="button"
              className="justify-center px-6 py-1 text-sm font-medium text-white bg-ss-hotpink border border-transparent rounded-md transition duration-150 hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
            >
              Post
            </button>
            <button
              onClick={saveDraft}
              type="button"
              className="justify-center px-6 py-1 text-sm font-medium text-white bg-ss-blue border border-transparent rounded-md transition duration-150 hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
            >
              Save Draft
            </button>
          </>
        :
          <>
            {draftEdit !== true ?
                <>
                  <h2 className="font-bold text-ss-blue">Create Draft</h2>
                  <button
                    onClick={saveDraft}
                    type="button"
                    className="justify-center px-6 py-1 text-sm font-medium text-white bg-ss-blue border border-transparent rounded-md transition duration-150 hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                  >
                    Save Draft
                  </button>
                </>
            :
                <>

                  <h2 className="font-bold text-ss-blue">Edit Draft</h2>
                  <button
                    onClick={updateDraft}
                    type="button"
                    className="justify-center px-6 py-1 text-sm font-medium text-white bg-ss-blue border border-transparent rounded-md transition duration-150 hover:bg-opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                  >
                    Save Edit
                  </button>

              </>
          }
          </>
        }
      </div>
      <div className="flex flex-row mt-10 mx-4 gap-x-4">
        <div className="flex flex-col">
          <div className="flex flex-row w-full text-gray-400">
            <p className="text-ss-blue text-lg font-bold">{currentUser.name}</p>
            <div className="justify-end flex flex-grow">
              <button
                onClick={startAnalyse}
                className="max-w-max max-h-px flex "
              >
                <img
                  width="100"
                  src={
                    analyse
                      ? "./imgs/analyseButtonT.svg"
                      : "./imgs/analyseButtonF.svg"
                  }
                  alt="analyse false"
                />
              </button>
            </div>
          </div>
          <div className="inline-flex mt-2">
            <div onClick={handleVideo}>
              {/* <PostButton btnIcon="./imgs/video.svg" btnText="Upload Videos" />
              <input
                type="file"
                accept="video/*"
                ref={hiddenVideoInput}
                hidden
              /> */}
            </div>
            <div onClick={handleImage}>
              <PostButton btnIcon="./imgs/photo.svg" btnText="Upload Photos" />
              <input
                onChange={(event) => handleImgPreview(event)}
                type="file"
                accept="image/*"
                multiple
                ref={hiddenImgInput}
                hidden
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-y-4 mt-8 mx-2">
        <textarea
          rows={4}
          type="text"
          placeholder={`What's on your mind, ${currentUser.name}?`}
          id="post"
          value={newPost}
          onChange={(event) => setNewPost(event.target.value)}
          className={`${
            hideTextbox ? "hidden" : ""
          } mt-2 leading-relaxed w-full self-center rounded-lg text-gray-800 bg-gray-50 border-gray-300 shadow-sm focus:border-gray-500 focus:ring-1 focus:ring-gray-200 focus:ring-opacity-50`}
        ></textarea>
        <ClipboardButton text={newPost} />
        <div
          className={`${hideTextbox ? "mx-2 mt-3" : "hidden"}`}
          onClick={async () => {
            await setHideTextbox(false);
            await setAnalyse(false);
          }}
        >
          <Highlighter
            caseSensitive={true}
            searchWords={keywords}
            textToHighlight={newPost}
            highlightStyle={{
              backgroundColor: highlightColor,
              borderRadius: "5px",
              paddingLeft: "4px",
              paddingRight: "4px",
            }}
          />
        </div>
      </div>
      <div className="grid gap-y-10 mt-10">
        {imgPreviews &&
          [...imgPreviews].map((img, index) => {
            return (
              <div className="relative">
                <img key={index} src={URL.createObjectURL(img)} alt="preview" />
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default CreatePostMobileModal;
