import { useEffect, useState } from "react";
import DeleteIcon from "../Icons/DeleteIcon";
import PrivacyIcon from "../Icons/PrivacyIcon";
import Tooltip from "../common/Tooltip";
import axios from "axios";
import jwt from "jsonwebtoken";

function Card({
  issue,
  keywords,
  setKeywords,
  deleteEntry,
  currentIndex,
  posts,
  setHighlightColor,
}) {
  // variable to check which keyword is highlighted currently so that bg color changes accordingly
  const [selected, setSelected] = useState(false);

  // apply the change each time the keyword state is changed
  useEffect(() => {
    setSelected(keywords.some((e) => e.test(issue)));
  }, [issue, keywords]);

  // highlight keyword on clicking the card
  const handleClick = async () => {
    setHighlightColor("rgba(23, 114, 193, 0.5)");
    await setKeywords([RegExp(`\\b${issue}\\b`)]);
  };

  const handleDelete = async () => {
    // name card
    await deleteEntry(issue);
  };

  const handleWhitelist = async () => {
    // name card
    const token = localStorage.getItem("authToken");
    if (!token) {
      window.location.href = "/login";
    } else {
      const decoded = jwt.verify(
        token,
        "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
      );

      var data = JSON.stringify({
        value: issue,
      });

      var config = {
        method: "post",
        url: `https://www.duodev.xyz/api/user/individual/filter/${decoded.id}/Name/Add`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(async (response) => {
          await deleteEntry(issue);
        })
        .catch(() => {
          alert("error");
        });
    }
  };

  return (
    <div
      onClick={handleClick}
      className={`cursor-pointer mt-4 flex flex-row gap-x-4 ${
        selected ? "bg-ss-document-blue text-white" : "bg-white"
      } bg-white rounded-lg p-4`}
    >
      <PrivacyIcon selected={selected} />
      <p className="font-medium self-center max-w-min">{issue}</p>
      <p
        className={`text-xs ${
          selected ? "text-white" : "text-gray-500"
        } self-center`}
      >
        Potential leaking term
      </p>
      <div className="flex flex-grow justify-end gap-x-4 self-center">
        <button onClick={handleDelete}>
          <DeleteIcon selected={selected} />
        </button>
        <Tooltip tooltipText="Add to Whitelist">
          <button type="button" onClick={handleWhitelist}>
            <img className="w-6 h-6" src="/imgs/checkmark.svg" alt="whitelist" />
          </button>  
        </Tooltip>
      </div>
    </div>
  );
}

export default Card;
