function GoogleButton({ text }) {
  const googleHandler = async () => {
    window.open("https://www.duodev.xyz/auth/google", "_self");
  };

  return (
    <button
      onClick={googleHandler}
      className="hover:bg-gray-50 text-gray-600 py-2 sm:pr-24 inline-flex items-center rounded-xl border-2 mt-8 font-light transition duration-150"
    >
      <svg
        className="mr-20 ml-4"
        width="15"
        height="16"
        viewBox="0 0 15 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.9926 8.14976C14.9926 7.49425 14.9416 7.0159 14.8311 6.51984H7.6499V9.47849H11.8651C11.7802 10.2138 11.3212 11.3211 10.3014 12.0651L10.2871 12.1642L12.5577 13.9976L12.715 14.014C14.1597 12.6232 14.9926 10.5769 14.9926 8.14976Z"
          fill="#4285F4"
        />
        <path
          d="M7.64971 15.9451C9.71482 15.9451 11.4485 15.2364 12.7148 14.014L10.3012 12.0651C9.65535 12.5346 8.78848 12.8624 7.64971 12.8624C5.62708 12.8624 3.91039 11.4716 3.29844 9.54938L3.20875 9.55732L0.84777 11.4619L0.816895 11.5513C2.07466 14.1557 4.65821 15.9451 7.64971 15.9451Z"
          fill="#34A853"
        />
        <path
          d="M3.29786 9.5493C3.13639 9.05324 3.04295 8.5217 3.04295 7.97251C3.04295 7.42326 3.1364 6.89177 3.28937 6.39571L3.28509 6.29006L0.894537 4.35492L0.816322 4.3937C0.297938 5.47443 0.000488281 6.68805 0.000488281 7.97251C0.000488281 9.25697 0.297938 10.4705 0.816322 11.5513L3.29786 9.5493Z"
          fill="#FBBC05"
        />
        <path
          d="M7.64969 3.08269C9.08591 3.08269 10.0547 3.72934 10.6071 4.26974L12.7657 2.07286C11.44 0.788397 9.71479 0 7.64969 0C4.6582 0 2.07466 1.78937 0.816895 4.39371L3.28994 6.39573C3.91038 4.47347 5.62706 3.08269 7.64969 3.08269Z"
          fill="#EB4335"
        />
      </svg>
      <span>{text}</span>
    </button>
  );
}

export default GoogleButton;
