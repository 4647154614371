import { useState, Fragment, useEffect, useRef } from "react";
import DeleteIcon from "../Icons/DeleteIcon";
import Tooltip from "../common/Tooltip";
import { round } from "../common/RoundDecimal";
import axios from "axios";
import jwt from "jsonwebtoken";

function PolarityCard({ issue, polarity, subjectivity, deleteEntry }) {
  const handleDelete = async () => {
    // links
    await deleteEntry(issue);
  };

  const handleWhitelist = async () => {
    // links
    const token = localStorage.getItem("authToken");
    if (!token) {
      window.location.href = "/login";
    } else {
      const decoded = jwt.verify(
        token,
        "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
      );
      var data = JSON.stringify({
        value: issue,
      });

      var config = {
        method: "post",
        url: `https://www.duodev.xyz/api/user/individual/filter/${decoded.id}/Link/Add`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(async (response) => {
          // console.log(issue);
          await deleteEntry(issue);
          // alert("done");
        })
        .catch(() => {
          alert("error");
        });
    }
  };

  return (
    <div
      className={`${ issue ? "cursor-pointer" : ""
        } flex flex-row gap-x-4 bg-white mt-2 rounded-lg p-4`}
    >
      
        <div className="flex flex-row gap-x-4">
          <img className="self-start" src="/imgs/emotions.svg" alt="emotion" />
         
          <div className="flex flex-col gap-5">
            <div className="flex flex-row gap-x-4">
              <h4 className="self-center">Sentiment</h4>
              {polarity >= 0 ? <p className="self-center text-sm text-gray-500">Positive Polarity</p> : <p className="self-center text-sm text-gray-500">Negative Polarity</p>}
            </div>

            <div className="font-semibold text-sm px-4 py-2 rounded-lg bg-red-500 text-white w-min">
              {issue}
            </div>

            <div className="flex flex-row gap-x-6 text-sm text-gray-500">
              <h5>Polarity: {round(polarity, 2)} </h5>
              <h5>Subjectivity: {round(subjectivity, 2)} </h5>
            </div>
        </div>

      </div>

      {issue && (
        <div className="flex flex-grow justify-end gap-x-4 self-end">
          <div onClick={handleDelete}>
            <DeleteIcon />
          </div>

          <Tooltip tooltipText="Add to Whitelist">
            <button data-tooltip-target="tooltip-default" type="button" onClick={handleWhitelist}>
              <img className="w-6 h-6" src="imgs/checkmark.svg" alt="whitelist" />
            </button>
          </Tooltip>
       </div>
      )}
    </div>
  );
}

export default PolarityCard;
