import { useEffect, useState } from "react";
import BigPolarity from "./BigPolarity";
import Card from "./Card";
import PolarityCard from "./PolarityCard";
import PrivacyCard from "./PrivacyCard";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

function Analyse({
  analyse,
  posts,
  setKeywords,
  deleteEntry,
  currentIndex,
  deleteEmail,
  keywords,
  analyseIssues,
  setAnalyseIssues,
  setHighlightColor,
  score,
  linkDelete,
  setPostsAvailable,
}) {
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [cardIssues, setCardIssues] = useState([]);
  const [privacyCardIssues, setPrivacyCardIssues] = useState([]);

  function checkNotNull (x) {
    if(!x){
      setPostsAvailable(false);
      return false;
    }
    setPostsAvailable(true);
    return true;
  }
  

  const next = async () => {
    await setCarouselIndex(carouselIndex + 1);
    setHighlightColor("rgba(249, 166, 17, 0.5)");
    setKeywords([RegExp(`\\b${privacyCardIssues[carouselIndex + 1]}\\b`)]);
  };

  const prev = async () => {
    await setCarouselIndex(carouselIndex - 1);
    setHighlightColor("rgba(249, 166, 17, 0.5)");
    setKeywords([RegExp(`\\b${privacyCardIssues[carouselIndex - 1]}\\b`)]);
  };

  useEffect(() => {
    //match based on word boundary to get complete words
    if(checkNotNull(posts[currentIndex])){
        setCardIssues(
          posts[currentIndex][1].filter(
            (word) => posts[currentIndex][0].search(RegExp(`\\b${word}\\b`)) >= 0
          )
        );
        setPrivacyCardIssues(
          posts[currentIndex][2].filter(
            (word) => posts[currentIndex][0].search(RegExp(`\\b${word}\\b`)) >= 0
          )
        );
    }
    else{
      console.log("Posts are empty");
    }

  }, [currentIndex, posts]);

  useEffect(() => {
    setCarouselIndex(0);
  }, [analyse]);

  useEffect(() => {
    setAnalyseIssues(privacyCardIssues.length > 0 || cardIssues.length > 0);

    if(posts[currentIndex][11]){
      setAnalyseIssues(posts[currentIndex][11].length>0);
    }

    if(posts[currentIndex][12]){
      setAnalyseIssues(posts[currentIndex][12].length>0);
    }
  }, [privacyCardIssues, cardIssues, setAnalyseIssues]);

  return (
    <div className="h-screen overflow-y-scroll z-10 sticky top-0 sm:flex min-w-max flex-col pt-2 px-5  bg-gray-50">
      {analyse ? (
        <div className="grid grid-flow-row">
          <div className="flex flex-row place-content-between gap-x-32 mt-4 text-ss-blue mx-2 font-semi-bold">
            <div className="grid grid-flow-row gap-y-1">
              {analyseIssues ? (
                <>
                  <h3>Issues Found</h3>
                </>
              ) : (
                <h3>No issues Found</h3>
              )}
            </div>
            <button onClick={()=>window.location.replace("/myaccount/#whitelist-settings")}>
              <p className="flex flex-row gap-x-1 self-center ml-auto">
                <span>
                  <img src="/imgs/settings.svg" alt="settings" />
                </span>
                Settings
              </p>
            </button>
          </div>
          <div
            className={`${
              posts[currentIndex][2].length > 0
                ? "flex justify-center relative"
                : "hidden"
            } `}
          >
            <button
              onClick={prev}
              className={`disabled:cursor-auto disabled:opacity-0 absolute top-32 left-5 z-10`}
              disabled={carouselIndex === 0}
            >
              <img
                className="transform rotate-180"
                src="/imgs/arrow.svg"
                alt="arrow"
              />
            </button>

            <Carousel
              selectedItem={carouselIndex}
              showArrows={false}
              width={360}
              showIndicators={false}
              showThumbs={false}
              showStatus={false}
            >
              {privacyCardIssues.map((issue, index) => (
                <PrivacyCard
                  key={index}
                  issue={issue}
                  deleteEmail={deleteEmail}
                  setKeywords={setKeywords}
                  carouselIndex={carouselIndex}
                  setCarouselIndex={setCarouselIndex}
                  arrayLength={privacyCardIssues.length}
                  setHighlightColor={setHighlightColor}
                />
              ))}
            </Carousel>
            <button
              onClick={next}
              className={`disabled:cursor-auto disabled:opacity-0 absolute top-32 right-10 z-10`}
              disabled={carouselIndex === privacyCardIssues.length - 1}
            >
              <img src="/imgs/arrow.svg" alt="arrow" />
            </button>
          </div>
          
          <div className="mx-2">
            {cardIssues.map((issue, index) => {
              return (
                <Card
                  key={index}
                  issue={issue}
                  deleteEntry={deleteEntry}
                  setKeywords={setKeywords}
                  keywords={keywords}
                  currentIndex={currentIndex}
                  posts={posts}
                  setHighlightColor={setHighlightColor}
                />
              );
            })}
          </div>
          
          <div className="mt-6">
            {posts[currentIndex][11] &&
              <>
                {posts[currentIndex][11].map((score, index) => {
                  // console.log("hey", score["dataNeeded"].score);
                  return (
                    <Card
                      issue={score["dataNeeded"].key}
                      key={index}
                      deleteEntry={deleteEntry}
                      setKeywords={setKeywords}
                      keywords={keywords}
                      currentIndex={currentIndex}
                      posts={posts}
                      setHighlightColor={setHighlightColor}
                    />
                  );
                })}
              </>            
            }
            {posts[currentIndex][12] &&
              <>
                {posts[currentIndex][12].map((sent, index) => {
                  return (
                    <PolarityCard
                      key={index}
                      issue={sent["sentiment"].sTerm}
                      polarity={sent["sentiment"].por}
                      subjectivity={sent["sentiment"].subj}
                      deleteEntry={deleteEntry}
                    />
                  );
                })}
              </>
            }
            {/* {console.log("dew", posts[currentIndex][3])}
            {console.log("score value", score)}
            {score && score.length > 0
              ? score[currentIndex].map((s) => {
                  console.log("hey", s);
                  // return (
                  //   <PolarityCard
                  //     score={s["dataNeeded"].score}
                  //     issue={s["dataNeeded"].key}
                  //   />
                  // );
                })
              : "" ?? posts[currentIndex][3]
              ? posts[currentIndex][].map((score) => {
                  console.log("hey", score);
                  return (
                    <PolarityCard
                      score={score["dataNeeded"].score}
                      issue={score["dataNeeded"].key}
                    />
                  );
                })
              : ""} */}
          </div>
        </div>
      ) : (
        <div>
          <div className="grid grid-flow-col mx-2 mt-4 text-ss-blue font-semi-bold">
            <h3>Analyse is on standby</h3>
            <button className="self-center ml-auto flex" onClick={()=>window.location.replace("/myaccount/#whitelist-settings")}>
              <p className="inline-flex gap-x-1">
                <span>
                  <img src="/imgs/settings.svg" alt="settings" />
                </span>
                Settings
              </p>
            </button>
          </div>
          <img
            className="w-3/4 mt-20 px-1.5 grid justify-center ml-auto mr-auto"
            src="/imgs/analyse.svg"
            alt="analyse"
          />

          <div className="text-gray-600 text-lg mt-10 flex justify-center gap-x-2">
            <p className="self-center">Click</p>
            <img
              width="100"
              src="/imgs/analyseButtonF.svg"
              alt="analyse false"
            />
            <p className="self-center">to activate</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Analyse;
