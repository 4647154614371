import Highlighter from "react-highlight-words";
import axios from "axios";
import jwt from "jsonwebtoken";
import { useEffect } from "react";

function ViewPost({
  index,
  textToHighlight,
  analyse,
  setAnalyse,
  setCurrentIndex,
  keywords,
  setKeywords,
  posts,
  active,
  currentIndex,
  // postsUsers,
  highlightColor,
  setHighlightColor,
  imageUrl,
  date,
  type,
  profilePicUrl,
  username,
  postType,
  postId,
  allPosts,
  setPosts,
}) {
  const startAnalyse = async () => {
    // await setAnalyse(!analyse);
    // // clear the keywords after each analyse toggle to false

    // // set index of current post after toggling analyse
    // setCurrentIndex(index);

    const token = localStorage.getItem("authToken");
    if (!token) {
      window.location.href = "/login";
    } else {
      const decoded = jwt.verify(
        token,
        "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
      );
      let data = JSON.stringify({
        desc: posts[index][0],
      });
      var startAnalyseconfig = {
        method: "post",
        url: `https://www.duodev.xyz/twitter/check/and/tell/${decoded.id}`,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios(startAnalyseconfig)
        .then(async (resp) => {
          const userPost = [
            resp.data[0],
            resp.data[1],
            resp.data[2],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            resp.data[3],
            resp.data[4],
          ];

          posts[index][1] = resp.data[1];
          posts[index][2] = resp.data[2];
          posts[index][11] = resp.data[3];
          posts[index][12] = resp.data[4];

          await setPosts([...allPosts]);
          await setCurrentIndex(index);
          await setAnalyse(!analyse);
          // console.log(setAnalyse();
          await setKeywords(
            [...resp.data[1], ...resp.data[2]].map((word) =>
              RegExp(`\\b${word}\\b`)
            )
          );
          // await setKeywords(
          //   [...posts[currentIndex][1], ...posts[currentIndex][2]].map((word) =>
          //   RegExp(`\\b${word}\\b`)
          // )
          // )
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (analyse) {
      setKeywords([new RegExp()]);
      // [...posts[currentIndex][1], ...posts[currentIndex][2]].map((word) =>
      //   RegExp(`\\b${word}\\b`)
      // )
    } else {
      setKeywords([new RegExp()]);
      // setHighlightColor("rgba(249, 166, 17, 0.5)");
    }
  }, [analyse, currentIndex, posts, setKeywords]);

  /// delete post
  // const deletePostHandler = async () => {
  //   let postID = postsUsers[index]._id;
  //   try {
  //     await axios.delete(`https://www.duodev.xyz/api/post/` + postID);
  //     console.log("Delete Post with ID: ", postID);
  //     window.location.replace("/");
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  /// delete twitter post
  const deletePostHandler = async () => {
    // providing userid
    switch (postType) {
      case "Facebook":
        facebookCotnentDelete();
        break;
      case "Twitter":
        twitterContentDelete();
        break;
      case "Instagram":
        instagramCotnentDelete();
        break;
      default:
        alert("error");
        break;
    }
  };

  // twitter handler

  const twitterContentDelete = async () => {
    const getToken = async () => {
      const token = localStorage.getItem("authToken");
      if (!token) {
        window.location.href = "/login";
      } else {
        try {
          const decoded = jwt.verify(
            token,
            "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
          );
          // console.log('Decoded: ', decoded);
          // let postID = postId;
          await axios
            .get(
              `https://www.duodev.xyz/twitter/delete/tweet/${decoded.id}/${postId}`
            )
            .then(() => {
              window.location.replace("/");
            });
          // let currentUser = res.data;
          // console.log('currentUser', currentUser);
        } catch (err) {
          console.log(err);
          localStorage.removeItem("authToken");
          window.location.href = "/";
        }
      }
    };

    getToken();
  };

  // instagram handler
  const instagramCotnentDelete = async () => {
    return alert("Instagram post can't be delete!");
  };

  // facebook handler
  const facebookCotnentDelete = async () => {
    return alert("Facebook post can't be delete!");
  };

  return (
    <div className="mx-3 sm:mx-10 lg:mt-10 py-10">
      <div className="flex flex-row gap-x-4 relative">
        <div className="mx-5 inline-flex relative">
          <div className="w-16 h-16 self-center">
            {posts.length >= 1 && profilePicUrl != null ? (
              <img className="rounded-full object-cover w-full h-full" src={profilePicUrl} alt="" />
            ) : (
              ""
            )}
          </div>
          {postType == "Twitter" ? (
            <img
              className="absolute bottom-0 -right-3"
              src="/imgs/twitter.svg"
              alt="twitter icon"
            />
          ) : postType == "Facebook" && posts != "" ? (
            <img
              className="absolute bottom-0 -right-3"
              src="/imgs/facebook.svg"
              alt="facebook icon"
            />
          ) : (
            ""
            // <img
            //   className="absolute bottom-0 -right-3"
            //   src="./imgs/facebook.svg"
            //   alt="facebook icon"
            // />
          )}
        </div>
        {posts.length > 1 ? (
          <div className="flex flex-row w-full gap-x-4 relative place-content-between">
            <div className="flex flex-col relative">
              <em className="not-italic font-semibold text-ss-blue">
                {username}
              </em>
              <div className="flex flex-col gap-x-2 lg:flex-row">
                <div>
                  {date}
                </div>

                <div>
                  {type ? (type[0] == "normal" ? "" : type[0]) : ""}
                </div>
              </div>
            </div>
            <button
              onClick={startAnalyse}
              className="mt-4 mx-3 max-w-max flex self-center"
            >
              <img
                className="min-w-px max-w-px"
                src={
                  analyse && active
                    ? "./imgs/analyseButtonT.svg"
                    : "./imgs/analyseButtonF.svg"
                }
                alt="analyse"
              />
            </button>
          </div>
        ) : (
          ""
        )}

        {/* {
          <div className="flex flex-col text-gray-400">
            <p className="text-ss-blue font-bold">
              {postsUsers.length > 0 ? postsUsers[index].username : null}
            </p>
            <p>{postsUsers.length > 0 ? postsUsers[index].createdAt : null}</p>
          </div>
        } */}

        {/* {posts.length <= 1 ? (
          ""
        ) : (
          <img
            className="ml-auto sm:mr-24 justify-end self-start cursor-pointer"
            src="/imgs/dropdown.svg"
            alt="dropdown"
            onClick={() => deletePostHandler()}
          />
        )} */}


      </div>

      {posts.length <= 1 ? (
        ""
      ) : (
        <div className="mx-5 mt-10 grid grid-flow-row gap-y-5">

          <Highlighter
            caseSensitive={true}
            searchWords={active ? keywords : [""]}
            textToHighlight={textToHighlight}
            highlightStyle={{
              backgroundColor: highlightColor,
              borderRadius: "5px",
              paddingLeft: "4px",
              paddingRight: "4px",
            }}
          />

        {imageUrl 
          ?
          <div className="lg:w-1/2 rounded-md overflow-hidden"> 
            <div className="grid grid-cols-2 grid-flow-row gap-2 lg:max-h-min">
              {imageUrl != null && imageUrl.length > 0
                ? 
                imageUrl.map((path, index) => {
                  /**
                   * Original intention for @param index was for it to act as a counter. You may change the counter based on needs.
                   * Current Status: Disabled due to lack of formatting.
                   */
                  if(index<=999){
                    return <img className="h-auto w-full object-cover rounded-md" src={path} />;
                  }
                  else{
                    return (
                    <div className="rounded-md bg-gray-100 w-full h-full text-gray-500" >
                      <p className="text-xl text-center">
                        View More...
                      </p>
                    </div>
                    )
                  }
                  })
                : 
                <></>
              }
            </div>
          </div>
          :
          <></>
        }      
      </div>
        
      )}
      {/* bottom section */}
      {type ? (
        type[0] != "normal" && posts.length > 1 ? (
          <div className="mt-5 mx-10 border-l-4 border-gray-300 pl-8 rounded">
            <div className="inline-flex relative">
              <img className="rounded-full" src={type[1].profile_image} alt="" />
              <img
                className="absolute bottom-0 -right-3"
                src="./imgs/twitter.svg"
                alt="twitter icon"
              />
            </div>
            <div className="flex flex-row gap-x-4 relative">
              <div className="flex flex-col relative">
                <div>
                  <em className="not-italic font-semibold text-ss-blue">
                    {type[1].username}
                  </em>
                  <div>
                    {date}
                  </div>
                </div>
                <div className="mt-10 grid grid-flow-row gap-y-5">
                  {type[1].post}
                </div>
              </div>
            </div>
            {type[1].media != null && type[1].media.length > 0
              ? 
              <div className="w-1/2 rounded-md overflow-hidden"> 
                <div className="grid grid-cols-2 grid-flow-row gap-2">
                    {
                    type[1].media.map((path, index) => {
                      return <img className="rounded-md" src={path} />;
                        /* if(index<=3){
                          return <img className="rounded-md" src={path} />;
                        }
                        else{
                          return <div className="bg-gray-100 w-full h-auto" />
                        } */
                      })
                    }
                </div>
              </div>
              :
            ""}
          </div>
        ) : (
          ""
        )
      ) : (
        ""
      )}
    </div>
  );
}

export default ViewPost;
