import SocialPill from "./SocialPill";
import { useState, useEffect } from "react";
import SocialPillMobile from "./SocialPillMobile";
import Dashboard from "../Icons/Dashboard";
import FacebookIcon from "../Icons/FacebookIcon";
import InstagramIcon from "../Icons/InstagramIcon";
import LinkedInIcon from "../Icons/LinkedInIcon";
import TwitterIcon from "../Icons/TwitterIcon";
import AddAccIcon from "../Icons/AddAccIcon";
import Draft from "../Icons/Draft";
import { Link } from "react-router-dom";
import jwt from "jsonwebtoken";
import axios from "axios";

function SideBar({
  selected,
  setSelected,
  getTwitterPosts,
  getFacebookPosts,
  getInstagramPosts,
  getOverallPosts,
  getDraftPosts,
}) {
  const [collapse, setCollapse] = useState(true);
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [twitter, setTwitter] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [pinterest, setPinterest] = useState("");
  const [drafts, setDrafts] = useState("");
  
  useEffect(() => {
    const fetchProfileDetails = () => {
      const token = localStorage.getItem("authToken");
      if (!token) {
        window.location.href = "/login";
      } else {
        const decoded = jwt.verify(
          token,
          "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
        );
        //axios to fetch information
        var profFetchConfig = {
          method: "get",
          url: `https://www.duodev.xyz/api/user/profile/${decoded.id}`,
        };
        axios(profFetchConfig)
          .then((response) => {
            setFacebook(response.data.facebookStatus);
            setInstagram(response.data.instagramStatus);
            setTwitter(response.data.twitterStatus);
            setLinkedin(false);
            setPinterest(false);
          })
          .catch((err) => { });
      }
    };
    fetchProfileDetails();

    // return () => {
    //   console.log("unmounted");
    // };
  }, []);

  return (
    <>
      <section className="h-screen z-10 sticky top-0 min-w-max hidden lg:block bg-gray-50 w-auto 2xl:w-auto shadow-xl">
        <div className="flex flex-col md:px-2 gap-y-5 pt-2 justify-center">
          <img
            className="cursor-pointer"
            alt="collapse"
            src="/imgs/collapse.svg"
            width="50"
            onClick={() => setCollapse(!collapse)}
          />
          {twitter || facebook || instagram.length > 0 ? (
            <SocialPill
              collapse={collapse}
              setSelected={setSelected}
              selected={selected}
              getPosts={getOverallPosts}
              icon={
                <Dashboard fill={"#8B8B8B"} mobile={false} notifications={0} />
              }
              text={"Overview"}
              social={false}
              notifications={0}
            />
          ) : (
            ""
          )}

          {facebook ? (
            <SocialPill
              collapse={collapse}
              setSelected={setSelected}
              selected={selected}
              getPosts={getFacebookPosts}
              icon={
                <FacebookIcon
                  fill={"#8B8B8B"}
                  mobile={false}
                  notifications={2}
                />
              }
              text={"Facebook"}
              social={true}
              notifications={2}
            />
          ) : (
            ""
          )}

          {instagram.length > 0 ? (
            <SocialPill
              collapse={collapse}
              setSelected={setSelected}
              selected={selected}
              getPosts={getInstagramPosts}
              icon={
                <InstagramIcon
                  fill={"#8B8B8B"}
                  mobile={false}
                  notifications={3}
                />
              }
              text={"Instagram"}
              social={true}
              notifications={3}
            />
          ) : (
            ""
          )}

          {twitter ? (
            <SocialPill
              collapse={collapse}
              getPosts={getTwitterPosts}
              setSelected={setSelected}
              selected={selected}
              icon={
                <TwitterIcon
                  fill={"#8B8B8B"}
                  mobile={false}
                  notifications={""}
                />
              }
              text={"Twitter"}
              social={true}
              notifications={""}
            />
          ) : (
            ""
          )}

          {linkedin ? (
            <SocialPill
              collapse={collapse}
              setSelected={setSelected}
              selected={selected}
              getPosts={() => { }}
              icon={
                <LinkedInIcon
                  fill={"#8B8B8B"}
                  mobile={false}
                  notifications={10}
                />
              }
              text={"LinkedIn"}
              social={true}
              notifications={10}
            />
          ) : (
            ""
          )}

          {pinterest ? (
            <SocialPill
              collapse={collapse}
              setSelected={setSelected}
              selected={selected}
              getPosts={() => { }}
              icon={
                <LinkedInIcon
                  fill={"#8B8B8B"}
                  mobile={false}
                  notifications={10}
                />
              }
              text={"Pinterest"}
              social={true}
              notifications={10}
            />
          ) : (
            ""
          )}

          <SocialPill
            collapse={collapse}
            setSelected={setSelected}
            selected={selected}
            getPosts={getDraftPosts}
            icon={
              <Draft fill={"#8B8B8B"} mobile={false} notifications={0} />
            }
            text={"Drafts"}
            social={false}
            notifications={0}
          />


          {/* <SocialPill
            collapse={collapse}
            setSelected={setSelected}
            selected={selected}
            getPosts={() => {}}
            icon={
              <AddAccIcon fill={"#8B8B8B"} mobile={false} notifications={0} />
            }
            text={"Add accounts"}
            social={false}
            notifications={0}
          /> */}
          {/* <button onClick={_twitterOauth}>Twitter</button>
          <br></br>
          <button onClick={_facebookOauth}>Facebook</button>
          <br></br>
          <button>Share on FB</button>
          <br></br>
          <button onClick={_instaOauth}>Instagram</button> */}
        </div>
      </section>
      <section className="lg:hidden z-20 bg-ss-black fixed bottom-0 w-full pt-3 rounded-t-xl">
        <div className="grid grid-flow-col justify-evenly">
          <Link to="/dashboard">
            <SocialPillMobile
              setSelected={setSelected}
              selected={selected}
              getPosts={getOverallPosts}
              icon={
                <Dashboard fill={"#C2CFE0"} mobile={false} notifications={0} />
              }
              text={"Overview"}
              social={false}
              notifications={0}
            />
          </Link>
          <SocialPillMobile
            setSelected={setSelected}
            selected={selected}
            getPosts={getFacebookPosts}
            icon={
              <FacebookIcon fill={"#C2CFE0"} mobile={true} notifications={2} />
            }
            text={"Facebook"}
            social={true}
            notifications={2}
          />
          <SocialPillMobile
            setSelected={setSelected}
            selected={selected}
            getPosts={getInstagramPosts}
            icon={
              <InstagramIcon fill={"#C2CFE0"} mobile={true} notifications={3} />
            }
            text={"Instagram"}
            social={true}
            notifications={3}
          />
          <SocialPillMobile
            setSelected={setSelected}
            selected={selected}
            getPosts={getTwitterPosts}
            icon={
              <TwitterIcon fill={"#C2CFE0"} mobile={true} notifications={""} />
            }
            text={"Twitter"}
            social={true}
            notifications={""}
          />
          <SocialPillMobile
            setSelected={setSelected}
            selected={selected}
            getPosts={getDraftPosts}
            icon={
              <Draft fill={"#C2CFE0"} mobile={true} notifications={""} />
            }
            text={"Drafts"}
            social={true}
            notifications={""}
          />
          <SocialPillMobile
            setSelected={setSelected}
            selected={selected}
            getPosts={()=>{window.location.replace("/myaccount")}}
            icon={
              <AddAccIcon fill={"#C2CFE0"} mobile={false} notifications={0} />
            }
            text={"Add accounts"}
            social={false}
            notifications={0}
          /> 
{/*           <SocialPillMobile
            setSelected={setSelected}
            selected={selected}
            icon={
              <LinkedInIcon fill={"#C2CFE0"} mobile={true} notifications={10} />
            }
            text={"LinkedIn"}
            social={true}
            notifications={10}
          />
        */}
        </div>
      </section>
    </>
  );
}

export default SideBar;
