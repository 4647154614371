export function round(
    value,
    minimumFractionDigits,
    maximumFractionDigits
) {
    const formattedValue = value.toLocaleString('en', {
        useGrouping: false,
        minimumFractionDigits,
        maximumFractionDigits
    })
    return Number(formattedValue)
}