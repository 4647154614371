import { Popover, Transition } from '@headlessui/react'
import DeleteIcon from "../Icons/DeleteIcon";
import TripleDots from "../Icons/TripleDots";
import EditIcon from "../Icons/EditIcon";
import Highlighter from "react-highlight-words";
import axios from "axios";
import jwt from "jsonwebtoken";
import { useEffect } from "react";

function ViewDraft({
  index,
  textToHighlight,
  analyse,
  setAnalyse,
  setCurrentIndex,
  keywords,
  setKeywords,
  posts,
  setPosts,
  allPosts,
  active,
  currentIndex,
  highlightColor,
  setHighlightColor,
  imageUrl,
  date,
  username,
  postId,
  setDraftsAvailable,
  seteditdraftId,
  setdraftEdit,
  openModal,
  isMobile,
  setPostMobile
}) {

  function checkNotNull(x) {
    if (!x) {
      setDraftsAvailable(false);
      return false;
    }
    setDraftsAvailable(true);
    return true;
  }

  const startAnalyse = async () => {
    // await setAnalyse(!analyse);
    // // clear the keywords after each analyse toggle to false

    // // set index of current post after toggling analyse
    // setCurrentIndex(index);

    const token = localStorage.getItem("authToken");
    if (!token) {
      window.location.href = "/login";
    } else {
      const decoded = jwt.verify(
        token,
        "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
      );
      let data = JSON.stringify({
        desc: posts[index][0],
      });
      var startAnalyseconfig = {
        method: "post",
        url: `https://www.duodev.xyz/twitter/check/and/tell/${ decoded.id }`,
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios(startAnalyseconfig)
        .then(async (resp) => {
          const userPost = [
            resp.data[0],
            resp.data[1],
            resp.data[2],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            resp.data[3],
            resp.data[4],
          ];
          posts[index][1] = resp.data[1];
          posts[index][2] = resp.data[2];
          posts[index][11] = resp.data[3];
          posts[index][12] = resp.data[4];

          await setPosts([...allPosts]);
          await setCurrentIndex(index);
          await setAnalyse(!analyse);
          // console.log(setAnalyse();
          await setKeywords(
            [...resp.data[1], ...resp.data[2]].map((word) =>
              RegExp(`\\b${ word }\\b`)
            )
          );
          // await setKeywords(
          //   [...posts[currentIndex][1], ...posts[currentIndex][2]].map((word) =>
          //   RegExp(`\\b${word}\\b`)
          // )
          // )
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (checkNotNull(posts[currentIndex])) {
      if (analyse) {
        setKeywords(
          posts[currentIndex][2].map((word) => RegExp(`\\b${ word }\\b`))
        );
      } else {
        setKeywords([new RegExp()]);
        setHighlightColor("rgba(249, 166, 17, 0.5)");
      }
    }
    else {
      console.log("No Drafts Available!");
    }
  }, [analyse, currentIndex, posts, setKeywords, setHighlightColor]);

  /// delete draft post
  const deleteDraft = async () => {
    const getToken = async () => {
      const token = localStorage.getItem("authToken");
      if (!token) {
        window.location.href = "/login";
      } else {
        try {
          const decoded = jwt.verify(
            token,
            "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
          );
          await axios
            .get(
              `https://www.duodev.xyz/twitter/draft/delete/post/${ decoded.id }/${ postId }`
            )
            .then(() => {
              window.location.replace("/");
            });
        } catch (err) {
          console.log(err);
          localStorage.removeItem("authToken");
          window.location.href = "/";
        }
      }
    };
    try {
      await axios.delete(`https://www.duodev.xyz/twitter/draft/delete/post/{id}/{postId}` + postId);
      console.log("Delete Post with ID: ", postId);
      window.location.replace("/");
    } catch (err) {
      console.log(err);
    }
    getToken();
  };

  const setDraftUpdate = async () => {
    seteditdraftId(postId);
    console.log("Draft Id: ",postId);
    setdraftEdit(true);
    if (isMobile) {
      await setPostMobile(true);
    } else {
        await openModal();
    }
  }
  /* const updateDraft = async () => {
    https://www.duodev.xyz/twitter/draft/update/:id/:postId
  } */

  return (
    <div className="mx-3 sm:mx-10 py-4 lg:py-10">
      <div className="flex flex-row gap-x-4 relative place-content-between">
        <div className="mx-4 flex flex-col relative">
          <div className="max-w-3/4 break-all">
            Draft {index + 1}
          </div>
          <div>
            {date}
          </div>
        </div>

        <div className="flex flex-col">

          {/* 
          * Delete & Edit Buttons Popover
          * Transition element defines the animation
          */}
          <Popover className="relative">
            <Popover.Button><TripleDots /></Popover.Button>

            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-75 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Popover.Panel className="absolute right-0.5 z-10 bg-gray-50 rounded-lg transition duration-150">
                <div className="flex flex-col divide-y px-4">
                  <button className="text-right flex flex-row gap-x-4 self-start w-full py-2" onClick={deleteDraft}>
                    <DeleteIcon /> Delete
                  </button>

                  <button className="text-right flex flex-row gap-x-4 self-start w-full py-2" onClick={setDraftUpdate}>
                    <EditIcon /> Edit
                  </button>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>

          <button
            onClick={startAnalyse}
            className="absolute lg:top-20 top-12 mt-4 mx-3 max-w-max flex self-end"
          >
            <img
              className="min-w-px max-w-px"
              src={
                analyse && active
                  ? "./imgs/analyseButtonT.svg"
                  : "./imgs/analyseButtonF.svg"
              }
              alt="analyse"
            />
          </button>

        </div>

        {/* {posts.length <= 1 ? (
          ""
        ) : (
          <img
            className="ml-auto sm:mr-24 justify-end self-start cursor-pointer"
            src="./imgs/dropdown.svg"
            alt="dropdown"
            onClick={() => deletePostHandler()}
          />
        )} */}
      </div>

      {posts.length < 0 ? (
        <></>
      ) : (
        <div className="mx-4 my-5 grid grid-flow-row gap-y-5">
          <Highlighter
            caseSensitive={true}
            searchWords={active ? keywords : [""]}
            textToHighlight={textToHighlight}
            highlightStyle={{
              backgroundColor: highlightColor,
              borderRadius: "5px",
              paddingLeft: "4px",
              paddingRight: "4px",
            }}
          />

        </div>
      )}
    </div>
  );
}

export default ViewDraft;
