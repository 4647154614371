import Clipboard from "../Icons/Clipboard";
import Tooltip from "../common/Tooltip";

const ClipboardButton = ({text}) => {
    /**
     * Copy to Clipboard Button
     * Uses ES6 Clipboard API
    */
    const copy = async () => {
        await navigator.clipboard.writeText(text);
        alert('Text copied');
    }
    
    return(
        <Tooltip tooltipText="Copy to Clipboard" disabled={!text} disabledText="No text to copy">
            <button disabled={!text} className="flex flex-row text-sm bg-gray-100 rounded-lg py-1 hover:bg-gray-200 transition duration-150 disabled:bg-gray-300 disabled:text-white px-3" onClick={copy} >
                <Clipboard disabled={!text} />
            </button>
        </Tooltip>
    );    
}

export default ClipboardButton;