import { useState, Fragment, useEffect } from "react";
import jwt from "jsonwebtoken";
import axios from "axios";

import AccountIcon from "../Icons/AccountIcon";
import LogoutIcon from "../Icons/LogoutIcon";
import NotificationIcon from "../Icons/NotificationIcon";
import PasswordIcon from "../Icons/PasswordIcon";
import ProfileIcon from "../Icons/ProfileIcon";

function MobileSettings({ selected, setSelected }) {

  const [email, setemail] = useState("");
  const [name, setname] = useState("");
  // fetch
  const fetchProfileDetails = () => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      window.location.href = "/login";
    } else {
      const decoded = jwt.verify(
        token,
        "68c52719702d649883111dd1d1d066992eabab38e45ddf6445f73875aa8f80c2dbf8ce"
      );
      //axios to fetch information
      var profFetchConfig = {
        method: "get",
        url: `https://www.duodev.xyz/api/user/profile/${decoded.id}`,
      };
      axios(profFetchConfig)
      .then((response) => {
        setemail(response.data.email);
        setname(response.data.name);
        })
        .catch((err) => {});
    }
  };
  
  useEffect(() => {
    fetchProfileDetails();
  }, []);


  return (
    <>
      <div className="pb-48 flex flex-col w-full gap-y-4">
        <div className="py-4 px-4 inline-flex shadow-lg text-ss-blue font-semibold">
          <button onClick={() => window.location.replace("/")}>
            <img
              className="flex transform -rotate-180 mr-5"
              src="/imgs/arrow.svg"
              alt="arrow"
            />
          </button>
          <h1 className="font-semi-bold">Settings</h1>
          <img
            className="flex justify-end ml-auto"
            src="/imgs/search.svg"
            alt="search"
          />
        </div>
        <div className="flex mx-4 gap-x-2">
          <div className="flex flex-col self-center">
            <h1 className="font-semibold">{name}</h1>
            <h3>{email}</h3>
          </div>
        </div>
        <hr className="border-gray-300 mx-4" />

        <div className="flex flex-col gap-y-4 mt-4 mx-4">
          <div
            onClick={() => setSelected("Profile Settings")}
            className="inline-flex gap-x-4 mr-2 cursor-pointer py-2"
          >
            <ProfileIcon fill={"ss-blue"} />
            <h4 className="text-lg font-semibold">Profile Settings</h4>
            <img
              className="w-6 h-6 transform -rotate-90 flex justify-end ml-auto"
              src="/imgs/dropdownarrow.svg"
              alt="arrow"
            />
          </div>
          <div
            onClick={() => setSelected("Account Settings")}
            className="inline-flex gap-x-4 mr-2 cursor-pointer py-2"
          >
            <AccountIcon fill={"ss-blue"} />
            <h4 className="text-lg font-semibold">Account Settings</h4>
            <img
              className="w-6 h-6 transform -rotate-90 flex justify-end ml-auto"
              src="/imgs/dropdownarrow.svg"
              alt="arrow"
            />
          </div>
          <div
            onClick={() => setSelected("Notification Settings")}
            className="inline-flex gap-x-4 mr-2 cursor-pointer py-2"
          >
            <NotificationIcon fill={"ss-blue"} />
            <h4 className="text-lg font-semibold">Notification Settings</h4>
            <img
              className="w-6 h-6 transform -rotate-90 flex justify-end ml-auto"
              src="/imgs/dropdownarrow.svg"
              alt="arrow"
            />
          </div>
          <div
            onClick={() => setSelected("Password Update")}
            className="inline-flex gap-x-4 mr-2 cursor-pointer py-2"
          >
            <PasswordIcon fill={"ss-blue"} />
            <h4 className="text-lg font-semibold">Password Update</h4>
            <img
              className="w-6 h-6 transform -rotate-90 flex justify-end ml-auto"
              src="/imgs/dropdownarrow.svg"
              alt="arrow"
            />
          </div>
          <div
            onClick={() => setSelected("Logout")}
            className="inline-flex gap-x-4 mr-2 cursor-pointer py-2"
          >
            <LogoutIcon fill={"ss-blue"} />
            <h4 className="text-lg font-semibold">Logout</h4>
            <img
              className="w-6 h-6 transform -rotate-90 flex justify-end ml-auto"
              src="/imgs/dropdownarrow.svg"
              alt="arrow"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default MobileSettings;
