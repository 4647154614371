import { Link } from "react-router-dom";

function NavBar() {
  return (
    <section className="pl-2 py-4 grid grid-flow-col shadow-sm z-50">
      <div className="flex items-center font-bold sm:z-10 px-3">
        <div className="hidden lg:flex bg-ss-blue h-12 w-12 items-center justify-center rounded-full text-white">
          <Link to="/">
            <h3>SS</h3>
          </Link>
        </div>
        <Link to="/">
          <h3 className="text-ss-blue ml-4 text-2xl lg:text-lg">Share Aware</h3>
        </Link>
      </div>
      <nav className="hidden lg:flex z-10 flex-1 gap-x-32 items-center text-gray-400">
        <Link to="#">
          <p>What's New</p>
        </Link>
        <Link to="#">
          <p>Help</p>
        </Link>
        <Link to="#">
          <p>Support</p>
        </Link>
        <Link to="/myaccount">
          <p>My Account</p>
        </Link>
      </nav>
    </section>
  );
}

export default NavBar;
