import { useParams, withRouter } from "react-router-dom";
import axios from "axios";

const ValidateView = () => {
  const { id } = useParams();

  const validateEmail = () => {
    try {
      var validateEmailConfig = {
        method: "get",
        url: `https://www.duodev.xyz/extra/validate/email/${id}`,
      };
      axios(validateEmailConfig)
        .then((response) => {
          window.location.href = "/login";
        })
        .catch((err) => {
          // this cna be redirect
          alert("error");
        });
    } catch (error) {
      // this cna be redirect
      alert("error");
    }
  };
  return (
    <div class="app font-sans min-w-screen min-h-screen bg-grey-lighter py-8 px-4">
      <div class="mail__wrapper max-w-md mx-auto">
        <div class="mail__content bg-white p-8 shadow-md">
          <div class="content__header text-center tracking-wide border-b">
            <img src="https://allmeans.com.sg/wp-content/uploads/2020/06/Final-01.png"></img>

            <div class="text-red text-sm font-bold">SS Share</div>
            <h1 class="text-3xl h-48 flex items-center justify-center">
              E-mail Confirmation
            </h1>
          </div>

          <div class="content__body py-8 border-b">
            <p>
              Welcome, <br></br>SS Share provide services...
            </p>
            <button
              onClick={validateEmail}
              class="text-black text-sm bg-sky-500/100 rounded w-full my-8 p-4 "
            >
              CONFIRM EMAIL ADRESS
            </button>
            <p class="text-sm">
              Love,<br></br>SS Share team
            </p>
          </div>

          <div class="content__footer mt-8 text-center text-grey-darker">
            <h3 class="text-base sm:text-lg mb-4">
              Thank you for using SS Share
            </h3>
            <p>SS Share</p>
          </div>
        </div>

        <div class="mail__meta text-center text-sm text-grey-darker mt-8">
          <div class="meta__help">
            <p class="leading-loose">
              Enquiries?{" "}
              <a href="#" class="text-grey-darkest">
                ss_share@allmeans.com
              </a>
              {/* <br></br> Want to quit getting updates?{" "} */}
              {/* <a href="#" class="text-grey-darkest">
                Unsubscribe
              </a> */}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValidateView;
